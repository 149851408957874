
@import url('./colors.css');
@media only screen and (min-width:0px) and (max-width:767px) {
    /*** css changes on 11/07/2024 ***/
    .public_bundle_product.public_ban_section  .owl-carousel .owl-item img {
        height: 200px;
    }
    body .custom_bunde_left {
        padding: 45px 20px;
    }
    body .shopping-cart-wrapper {
        right: 0;
    }
    .cus_pro_quan_blk>* {
        width: 50%;
    }
    .cus_pro_quan_blk>*:nth-child(2) {
        text-align: right;
    }
    .cus_pro_order_blk.order_pro_list .order_pro_det,  .cus_pro_order_blk .quan_cart_blk.add_to_bundle {
        width: 100% !important;
    }
    .cus_pro_order_blk .pro_qty_cat_blk>.pl_10 {
        padding-left: 0;
    }
    .cus_pro_order_blk .pro_qty_cat_blk {
        margin: 0;
    }
    .cus_pro_order_blk.order_pro_list .order_pro_det {
        padding-bottom: 0;
    }
    /*** css changes on 11/07/2024 Ends ***/
    .modal-dialog {
        margin: 20px auto;
        width: 90% !important;
    }
    .agree_section{
        padding: 0 20px; 
    }
    .td_width{
        width: 50%;
    }
    .mobile_interchange {
        flex-direction: column-reverse;
        align-items: flex-start !important;
    }
    .mob_site_menu {
        background: url(../images/icon/menu_bg.jpg) no-repeat center center;
        background-position: 20px !important;
    }
    .mob_main_cart {
        background: url(../images/icon/mob_cart.jpg) no-repeat center center;
    }
    .mob_main_user {
        background: url(../images/icon/user.jpg) no-repeat center center;
    }

    .hide_small_screen{
        display: none;
    }
    .mob_menu_wrapper .site_menu_wrapper_inner{
        clear: both;
        width: 100%; 
    }
    .mob_site_menu, .mob_main_user, .mob_main_cart {
        height: 45px;
        width: 32.3%;
        display: inline-block;
        vertical-align: top;
        color: var(--default-color);
        text-align: center;
    }

    .mob_main_user {
        border-right: 1px solid #868686;
        border-left: 1px solid #868686;
        border-top: 0px solid #868686;
        border-bottom: 0px solid #868686;
    }
    .mob_site_content {
        left: -800px;
        position: absolute;
        top: 0px;
        width: 260px;
        height: 500%;
        z-index: 9999;
        overflow-y: auto;
        /*        background: url(//cdn.shopify.com/s/files/1/2553/6586/t/8/assets/box-shadow-mobile.png?1210082…) right 0px repeat-y rgb(255, 255, 255);*/
        background-image: url(../images/box-shadow-mobile.png);
        background-position: right 0px;
        background-repeat: repeat-y;
        background-color: rgb(255, 255, 255);
        transition: all 0.2s ease-in-out 0s;
    }


    .mob_main_user_wrapper {
        top: 44px;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, .15);
        width: 100%;
        left: 0;
        text-align: left;
        background: var(--default-color);
        z-index: 1;
        padding: 10px 15px;
    }
    .mob_main_user_wrapper ul li {
        margin: 0 0 8px 0;
        color: #323232;
        list-style: none;
    }
    .mob_main_user_wrapper ul li a {
        color: #000;
        font-family: "Montserrat",sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
    body.current .mob_site_content {
        left: 0;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
    body.current {
        left: 0;
        width: 100%;
        overflow: hidden;
        position: fixed;
        height: 100%;
    }
    .mob_site_content li a {
        font-size: 13px;
        font-weight: 700;
        color: #000;
        padding: 12px 20px;
        margin: 0;
        display: block;
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
    }
    .mob_site_content li {
        border-bottom: 1px solid var(--text_color);
        display: flex;
        flex-wrap: wrap;
    }
    .mob_site_content li>* {
        width: 100%;
    }
    .mob_site_content .shop_menu li {
        border: none;
        margin: 0;
        width: 100%;
    }
    .mob_site_content .shop_menu li>* {
        width: auto;
        font-size: 13px !important;
        line-height: 19px !important;
        padding: 0 0 10px 0;
        text-transform: none;
    }
    .mob_text_order {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #e7e7e7;
        padding: 9px 10px;
        color: #141414;
        margin: 0;
        line-height: 30px;
        letter-spacing: 0.02em;
    }
    .mob_header_logo {
        border-bottom: 1px solid #e7e7e7;
        float: left;
        margin: 28px 0 20px;
        padding-bottom: 38px;
        text-align: center;
        width: 100%;
    }
    .menu_close>* {
        text-align: right;
    }
    .mob_header_logo a img {
        width: 147px;
    }
    .site-header {
        margin: 0;
    }
    .user_login {
        width: 100%;
    }
    .user_login .inline_checkbox{
        margin: 10px 0 -5px 0;
    }
    .new_distributor_sign_up {
        padding: 0;
        margin: 12px 0 -7px 0;
    }
    #affiliateURL {
        width: 87%;
        margin: 0 20px 0 5px;
        /* display: inline-block; */
    }
    .lost_password{
        margin: 0px;  
    }
    .footer-top {
        text-align: center;
    }
    .footer_contact {
        margin: 0 0 6px 0;
    }
    .site-footer h3 {
        margin: auto auto 10px auto;
    }
    .site-footer .widget {
        margin: 15px 0;
    }
    .footer-top ul.social, .footer-top .text {
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin: 0 6px;
    }
    .user_name_email_field input,
    .cus_button,
    .input_type_file input[type=file],
    .name_field,
    .user_registration {
        width: 100%;
    }
    .product-list-container {
        width: 100%;
    }
    .scrollup {
        padding: 0px 10px;
        right: 15px;
        top: 90%;
    }
    .cart_add_product_img {
        width: 80px;
        height: 80px;
    }
    .variant-changer {
        width: 100px;
    }
    .my_account_orders thead{
        display: none;
    }
    .shop_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
    }
    td.cart_page_product_action::before,
    .shop_table_responsive tr td.cart_product_remove::before{
        display: none;
    }
    .shop_table_responsive tr td {
        display: block;
        text-align: right;
        border-width: 0px 0px 1px 0px;
    }
    .shop_table_responsive tr td:last-child {
        border-width: 1px 0px 0px 0px !important;
        padding: 15px;
    }
    .shop_table_responsive tr{
        display: block;
        margin-bottom: 20px;
    }
    .table_search {
        text-align: left;
    }
    .table_search select, .table_search input {
        width: 100% !important;
        /* margin: 6px 0 !important; */
    }
    .inner_content{
        margin-top: 20px;
    }
    .my_account_orders tr td .order-rsp-change {
        margin: 0 0 0 10px;
        float: none;
    }
    .page-title {
        font-size: 14px;
        margin: 0 0 10px 0;
    }
    .my_account_orders {
        margin-bottom: 15px;
    }
    .billing_address_container {
        width: 100%;
        margin: 30px 0 0 0;
    }
    .left_menu{
        margin: 0 0 20px 0;
        padding: 15px;
    }
    .get_in_touch .form-group input[type=text]{
        width:100%;
    }
    .my_account .col-md-6{
        padding: 0px;
        margin: 10px 0;
    }
    .action_form{
        padding: 15px;
    }
    .my_account .cus_button{
        width: auto;
    }
    .terms_and_condition h3 {
        font-size: 16px;
        margin: 0 0 3px 0;
    }
    .athletes_list img {
        width: 100%;
        height: auto;
    }
    .commission_aside, .order_aside {
        width: 100%;
        background: #f9f9f9;
        padding: 10px;
    }
    .page-content.entry-content p{
        line-height: 24px;
    }
    .shipping-address{
        font-size: 14px; 
    }
    .subscription_end_date_edit_calender input {
        width: 100%;
        margin: 0 0 4px 0;
    }
    .subscription_end_date_edit_calender a {
        display: block;
        margin: 0 0 4px 0;
    }
    .order-actions a {
        display: block;
        vertical-align: middle;
        margin: 4px 0px;
    }
    .date_by_day {
        width: 100%;
        margin: 0 0 15px 0;
        padding: 10px 0 0 0;
    }
    .table_search label {
        margin: 0 0 2px 0;
    }
    .page-title a{
        float: none;
        display: block;
    }
    .mob_left_right_none{
        float: none;
    }
    .word_break{
        word-break: break-all;
    }
    .mob_order_action_inline a{
        display: inline-block;
        margin: 0 10px;
    }
    .section_blog label {
        display: block;
    }
    .my_paypal_form .cus_field {
        width: 100%;
    }
    .label_block{
        display: block;
    }
    .cart_page_product_img{
        display: none;
    }
    .cart_table tr td.product-thumbnail {
        width: 100%;
    }
    .cart_product_number .decrement,
    .cart_product_number .qty,
    .cart_product_number .increment{
        display: inline-block;
        vertical-align: middle;
        float:none;
    }
    .cart_product_remove {
        width: 58px;
        border-width: 0 1px 1px 0 !important;
        text-align: center !important;
    }
    .cart_page_data_list{
        border: 1px solid  #e1e1e1;
    }
    .cart_page_product_coupon{
        float: none;
        margin-bottom: 10px;
    }
    body .public_bundle_product.public_ban_section .owl-carousel .owl-item img {
        height: 170px;
    }
    .cart_page_product_coupon .cus_button{
        width: 40%;
    }
    .countinue_shop{
        float: none;
    }
    .cart_page_product_coupon .input-text, .countinue_shop .update_cart {
        float: left !important;
        margin: 0 !important;
        width: 47% !important;
    }
    .no_display_mobile{
        display: none !important;
    }
    .cart_totals {
        padding: 10px;
    }
    .cart_totals .shop_table_responsive tr {
        display: block;
        margin-bottom: 0px;
    }
    .shipping-methods{
        font-size: 13px;
    }
    .col2-set {
        width: 100%;
    }
    .woocommerce-checkout-payment {
        padding:10px;
        margin: 0 0 20px 0;
    }
    .payment_box {
        padding: 0px;
        margin: 10px 0;
    }
    .fieldset {
        padding: 5px 5px;
    }
    .checkout_coupon {
        float: none;
        background: #e8e8e8;
        padding: 10px;
        margin: 0 0 15px 0;
    }
    .checkout_coupon .input-coupon,
    .checkout_coupon .cus_button{
        display: inline-block;
        vertical-align: top;
        float: none !important;
        width: 47%;
    }
    .order_details_list li {
        width: 100%;
        display: block;
        overflow: hidden;
        border-style: dotted;
        border-width: 0px 0px 1px 0px;
        border-color: #d3ced2;
        margin: 0 0 5px 0;
        padding: 0 0 4px 0;
    }
    .order_details_list li strong {
        display: inline-block;
        float: right;
    }
    .woocommerce-notice.woocommerce-notice--success.woocommerce-thankyou-order-received{
        padding: 15px;
    }
    .receive_order_details{
        margin-bottom: 20px;
    }
    .receive_order_details thead tr th{
        padding: 0 10px;
    }
    .checkout-payment-card-opt img {
        width: 70%;
    }
    .team_member_edit select {
        display: inline-block;
        margin: 0;
        width: auto;
    }
    .coupon-wrapper-container {
        width: 100%;
        margin-bottom: 0;
    }
    .error404Img img{
        width: 100%;
    }
    .affilate_create_blk {
        flex-direction: column;
    }
    .affilate_create_blk .product_itle {
        margin-bottom: 10px !important;
        width: 100%;
    }
    .affilate_create_blk .aff_bundle_blk {
        width: 100%;
        flex-direction: column;
    }
    .affilate_create_blk .aff_bundle_blk .product-search-form {
        width: 100%;
        margin: 0 0 15px;
    }
    .affilate_create_blk .aff_bundle_blk .shop_more.take_quiz_btn {
        margin: 0;
        text-align: center;
        width: 100%;
    }
}

@media only screen and (min-width:320px) and (max-width:1024px) {



}

@media only screen and (min-width:0px) and (max-width:767px) {
    body .font_44 {
        font-size: 24px;
        line-height: 38px;
    }
    body .mr_27 {
        margin-bottom: 20px;
        margin-right: 0;
    }
    body .second_options_blk {
        min-height: inherit;
        margin-bottom: 50px;
    }
    body .quiz_options.sec_quiz_options:not(:last-child) {
        margin-bottom: 27px;
        margin-right: 0;
    }
    body .ml_27 {
        margin-left: 0;
        margin-top: 10px;
    }
    body .gender_quiz_button, body .first_quiz_button {
        width: 100%;
    }
    body .next_button.buy_now {
        min-width: 170px;
        height: 60px;
    }
    body .quiz_blk {
        padding: 25px 25px 85px 25px;
    }
    body .banner_section.public_bundle_product .owl-theme .owl-nav [class*='owl-'] {
        display: none;
    }
    body .refer_banner_slide.public_banner_slide .carousel-item {
        padding: 170px 15px 40px 15px;
        background-position: top right;
    }
    body .public_ban_text {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 30px;
    }
    body .promotes_text {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    body .font_20, body .font_24 {
        font-size: 16px;
        line-height: 18px;
    }
}

@media only screen and (min-width:0px) and (max-width:756px) {

}

@media only screen and (min-width:768px) and (max-width:1900px) {

}
/** office samsung tab **/
@media only screen and (min-width:768px) and (max-width:1024px) {
    body .font_44 {
        font-size: 34px;
        line-height: 50px;
    }
    body .quiz_blk {
        min-height: inherit;
    }
    body .public_bundle_product.public_ban_section  .owl-carousel .owl-item img {
        height: 300px;
    }
    body .refer_banner_slide.public_banner_slide .carousel-item {
        padding: 40px 35px;
        background-size: cover;
        background-position: calc(100% - -87px);
    }
    .public_banner_slide .row>* {
        flex: 0 0 50%;
        max-width: 50%;
    }
    body .public_ban_text {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 30px;
    }
    .promotes_text {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 40px;
    }
    .login_container .col-md-3.empty {
        display: none;
    }
    .login_container .col-md-6 {
        margin: 0 auto;
        flex: inherit;
        max-width: inherit;
    }
    body .login_container {
        min-height: calc(100vh - 193px);
        align-items: flex-start !important;
    }
    .site_menu ul {
        margin: 0px 0px 0px 100px;
    }
    .entry-content .faq_container{
        padding: 10px;
    }
    .coupon-wrapper-container {
        width: 100%;
        margin-bottom: 0;
    }
    .error404Img img{
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {

}


/** this css for ipad office **/


@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1)
and (orientation: portrait){
    .error404Img img{
        width: 100%;
    }
    .coupon-wrapper-container {
        width: 100%;
        margin-bottom: 0;
    }
    .product-list-container {
        margin: auto auto 50px auto;
        width: 100%;
    }
    .my_account_orders tr td, .my_account_orders tr th, .receive_order_details tr td {
        padding: 5px;
        font-size: 13px;
    }
    .cart_product_details {
        margin: 0 5px;
        width: 176px;
    }
    .my_account_orders tr td a, .receive_order_details tr td a{
        font-size: 13px;
    }
    .cart_product_number input {
        padding: 5px;
        width: 50px;
        border-radius: 0px;
    }
    .increment, .decrement {
        background: #dddddd;
    }
    .cart_table .actions .input-text{
        border-radius: 0px;
    }
    .coupon.checkout_coupon{
        width: 100%;
        float: none;
        margin: 0 0 15px 0;
    }
    .woocommerce-checkout-payment {
        padding: 10px;
        margin: 0 0 20px 0;
    }
    .payment_box {
        padding: 8px;
        margin: 10px 0;
        border-radius: 0;
        background-color: #f6f6f6;
        color: inherit;
        font-size: 13px;
    }
    .fieldset {
        padding: 5px;
        margin: 0px;
    }
    .review-order-table-wrapper {
        margin: 0 0 20px 0;
    }
    .cart_totals {
        padding: 10px;
    }
    .left_menu {
        padding: 5px;
    }
    .page-title {
        font-size: 18px;
        margin-bottom:18px;
    }
    .table_search select, .table_search input {
        width: 23%;
    }

    .my_account_orders thead{
        display: none;
    }
    .shop_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
    }

    /*.shop_table_responsive tr td.order-representative::before,
    .shop_table_responsive tr td.order-total::before{

    }*/
    .order-actions::before,
    td.cart_page_product_action::before,
    .shop_table_responsive tr td.cart_product_remove::before{
        display: none;
    }
    .shop_table_responsive tr td {
        display: block;
        text-align: right;
        border-width: 0px 0px 1px 0px;
    }
    .shop_table_responsive tr td:last-child {
        border-width: 1px 0px 0px 0px !important;
        padding: 15px;
    }
    .shop_table_responsive tr{
        display: block;
        margin-bottom: 20px;
    }
    .my_account_orders tr td .order-rsp-change {
        float: none;
    }

    .table_search select, .table_search input {
        width: 40%;
        float: left;
    }
    .order_search.table_search select, .order_search.table_search input {
        display: inline-block;
        width: 110px;
        margin: 0 0 0 6px;
        float: none;
    }
    .to_hide span.member_edit_cancel{
        width: auto;
    }
    .table_search_form_group label {
        display: block;
        text-align: left;
    }
    .date_by_day {
        width: 64%;
        float: left;
    }
    .shop_table_responsive .order-number a{
        float: none;
    }
    .team_member_edit select {
        display: inline-block;
        margin: 0;
        width: auto;
    }
    .entry-content .faq_container{
        padding: 10px;
    }
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) 
and (orientation: landscape){

}
/** //EnD this css for ipad office **/

/**** css changes on 17/03/2023 **********/
@media only screen and (max-width: 767px){
    .active_cancel_mob_sub>* {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }
    .active_cancel_mob_sub>*.mr_36 {
        margin-right: 0 !important;
    }
    img.mr_3 + h3 {
        width: calc(100% - 30px);
    }
    .mobile_mar_top {
        margin-top: 15px;
    }
    .d-flex {
        flex-wrap: wrap;
    }
    .customer_menu li {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 15px;
    }
    .customer_menu li a {
        display: flex;
        justify-content: center;
    }
    .sub_btm_det {
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
    }
    .order_pro_detail>* {
        width: 100% !important;
    }
    .order_pro_detail>*:not(:last-child) {
       margin-bottom: 10px;
    }
    .order_on_div, .order_status, body .w-50 {
        width: 100% !important;
    }
    body .order_accordatio {
        padding: 20px;
    }
    .order_status.ml_20 {
        justify-content: center;
        width: fit-content !important;
    }
    .rgt_acc {
        width: 100% !important;
        margin-top: 10px;
    }
    .rgt_acc a {
        margin-bottom: 17px;
    }
    .order_det_blk {
        margin-top: 20px;
        padding: 20px;
    }
    .billing_blk {
        margin-bottom: 25px;
    }
    .pay_top {
        margin-top: 25px;
    }
    .subscription_ul {
        width: 1140px;
    }
    body .sub_current_div {
        padding: 20px;
    }
    .sub_current_div>*>* {
        width: 100% !important;
    }
    .billing_blk.sub_billing_blk.mb_25 {
        margin-top: 25px;
    }
    .sub_current_div .col-md-4 {
        justify-content: flex-start !important;
        margin-top: 16px;
    }
    .sub_current_div>*>*.buy_now {
        width: fit-content !important;
    }
    body .add_new_card {
        padding: 20px;
    }
    .form_input_blk  form {
        padding: 0;
    }
    .add_new_btn {
        width: 100%;
    }
    .form-control>input {
        width: 50% !important;
    }
    body .banner_heading {
        font-size: 35px;
        width: 100%;
        line-height: 46px;
        margin-bottom: 20px;
    }
    .mr_36 {
        margin-right: 15px !important;
    }
    body .buy_now, body .view_detail {
        padding: 13.5px 20px;
    }
    body .font_41 {
        font-size: 24px;
        line-height: 35px;
    }
    body .best_selling_para {
        width: 100%;
    }
    body .best_selling_section, body .explore_product_section, body .shop_goal_section, body .explore_product_section.testimonial_section,
    body section.your_fitness_section {
        padding-left: 0;
        padding-right: 0;
    }
    body .exp_col_col {
        width: 100%;
        margin-bottom: 20px;
    }
    .goal_row.row>* {
        padding-bottom: 13px;
    }
    body .testsimonial_col {
        padding: 20px;
    }
    body .testsimonial_col {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .testi_left {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    .testi_right {
        padding: 20px 0 0 0;
    }
    body .fitness_left, body .fitness_rgt, body .fitness_left img, body .our_story_left, .our_story_left img {
        width: 100%;
    }
    body .fitness_text {
        font-size: 35px;
        line-height: 46px;
        margin-top: 25px;
    }
    body .our_story_section{
        padding-left: 15px;
        padding-right: 15px;
    }
    .play_icon{
        width: 50px !important;
    }
    body .our_story_right {
        width: 100%;
        padding: 20px;
    }
    body .site-footer.refer_footer {
        padding: 30px 0px;
    }
    .location_blk span {
        width: calc(100% - 13px);
    }
    body .site-footer.refer_footer .row>*:not(:last-child) {
        padding-bottom: 30px;
    }
    body .pro_left_blk {
        padding: 30px 15px;
    }
    nav[aria-label="breadcrumb"] + img {
        margin-top: 20px;
    }
    body .pro_rgt_blk {
        padding: 30px 15px;
    }
    body .quan_cart_blk .cart_add_product_btn {
        width: calc(100% - 140px);
    }
    .del_pri {
        width: 30%;
    }
    .del_det {
        width: 70%;
        padding-left: 10px;
    }
    .dis_span.ml_16 {
        margin: 10px 0 !important;
    }
    body .product_tabs li.nav-item {
        margin: 0 0 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    body .product_tabs li.nav-item>* {
        margin:  0;
    }
    body .ben_letf,  body .ben_letf img, body .howuse_heading, body .faq_section .container,
    body .like_pro_para, .wri_review_blk >* {
        width: 100% !important;
    }
    body .ben_rgt {
        width: 100%;
        padding: 20px 0 0 0;
    }
    .benefit_ul li span {
        padding-left: 15px;
        width: calc(100% - 24px);
    }
    .benefits_blk {
        padding-bottom: 30px;
    }
    .how_use_row>* {
        padding-bottom: 30px;
    }
    .faq_section .accordion-header .accordion-button {
        position: relative;
    }
    .faq_section .accordion-header .accordion-button img {
        position: absolute;
        right: 15px;
    }
    body .wri_review_blk {
        padding: 20px;
    }
    .rev_rgt  {
        padding-top: 20px;
    }
    .cart_pro_list .cart_pro_list_left {
        width: 100%;
        padding-bottom: 15px;
    }
    .cart_pro_list .cart_total {
        width: 100%;
    }
    .cart_rgt_blk {
        margin-top: 30px;
    }
    body .coupon_input {
        width: calc(100% - 82px);
    }
    .checkout_left_blk {
        padding: 30px 15px;
    }
    .checkout_row  .shop_more.take_quiz_btn {
        margin-top: 20px;
    }
    .checkout_ul li .pl_10 {
        padding: 10px 0 0 0;
    }
    .checkout_ul li {
        flex-direction: column;
    }
    .checkout_row>* {
        padding-bottom: 16px;
    }
    body .checkout_rgt_blk {
        padding: 30px 15px;
    }
    .shipping_left>*, body .order_confirmed_page .container {
        width: 100%;
    }
    .shipping_left>*.pl_6 {
        padding-top: 6px;
        padding-left: 0;
    }
    .upi_row.row>* {
        padding-bottom: 20px;
    }
    .checkbox_label .checbox + span {
        width: calc(100% - 14px);
    }
    body .thank_you_header, body .thank_you_body, body .thank_btn {
        padding: 20px;
    }
    ul.pro_cart_det.payemnt_total_pri {
        margin-top: 20px;
    }
    .order_no_date span {
        margin-bottom: 10px;
        text-align: left !important;
    }
    .thank_btn>*>.col-md-8 {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    body .cart_pro_list_left {
        width: 50%;
    }
    .subscription_end_date_edit_calender>*>*.modal-content {
        width: 100% !important;
    }
    .site-footer.refer_footer .row>* {
        padding-bottom: 30px;
    }
    .main_menu_blk {
        display: none;
    }
    .banner_section .banner_right_img {
        width: 100% !important;
        height: inherit;
    }
    body .banner_heading {
        font-size: 41px;
        line-height: 53px;
        margin-bottom: 35px;
        width: 100% !important;
    }
    body .buy_now, body .view_detail {
        padding: 13px 20px;
    }
    .best_selling_pro .col-md-3, .goal_row  .col-md-4 {
        width: 50%;
        flex: inherit;
        max-width: inherit;
    }
    .goal_row  .col-md-4 {
        padding-bottom: 18px;
    }
    body .explore_img {
        width: 100%;
        height: 100%;
    }
    body .exp_col_col {
        width: 100%;
        margin: 0 auto;
    }
    .exp_col_col  h3 {
        text-align: center;
    }
    .header_blk .hide_small_screen .container-fluid,body .explore_product_section, body .shop_goal_section,
    body .explore_product_section.testimonial_section, body section.your_fitness_section, body .site-footer.refer_footer {
        padding-left: 20px;
        padding-right: 20px;
    }
    .Testimonial_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
    body .fitness_left {
        width: 30%;
    }
    body .fitness_left img, body .our_story_left img, body .our_story_left, body .our_story_right {
        width: 100%;
    }
    body .fitness_rgt {
        width: 70%;
    }
    body .fitness_text {
        font-size: 41px;
        line-height: 46px;
    }
    .our_story_section>.d-flex {
        flex-wrap: wrap;
    }
    .play_icon {
        width: 50px !important;
    }
    .site-footer.refer_footer .row>.col-md-4 {
        width: 66%;
        flex: inherit;
        max-width: inherit;
        padding-bottom: 30px;
    }
    .site-footer.refer_footer .row>.col-md-2 {
        width: 33.33%;
        flex: inherit;
        max-width: inherit;
    }
    .site-footer.refer_footer .row>.col-md-4 + * {
        display: none;
    }
    .pro_left_blk.col-md-6, .pro_rgt_blk.col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 20px;
    }
    body .product_tabs>li .nav-link {
        margin-right: 20px;
    }
    body .ben_letf {
        width: 30%;
    }
    body .ben_letf img, body .howuse_heading {
        width: 100%;
    }
    body .ben_rgt {
        width: 70%;
        padding-left: 15px;
    }
    .how_use_row>* {
        flex: inherit;
        max-width: inherit;
        width: 50%;
        padding-bottom: 30px;
    }
    body .faq_section .container {
        width: 720px;
    }
    body .rev_left {
        width: 40%;
    }
    body .checkout_left_blk, body .checkout_rgt_blk {
        max-width: inherit;
        flex: inherit;
        width: 100%;
        padding: 30px 20px;
    }
    .dashboard_row>.col-md-4 {
        max-width: inherit;
        flex: inherit;
        width: 100%;
    }
}
/**** css changes on 17/03/2023 **********/

/**** css changes on 19/03/2023 **********/
@media only screen and (max-width: 767px) {
    body .pro_filter_sec {
        width: calc(100% - 30px);
    }
    .pro_filter_sec>*:first-child, .pro_filter_sec>*:nth-child(2) {
        margin-bottom: 10px;
    }
    .header_blk .mob_header_logo {
        border: none;
        margin: 0;
        float: none;
        padding: 20px;
    }
    .headere_mobile .mob_site_menu {
        width: 50px;
        background: url('../images/public_images/menu.svg') no-repeat center;
    }
    .headere_mobile {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 15px 15px 0;
        align-items: center;
    }
    .headere_mobile .header_right_blk>ul>li {
        width: 33.33%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid var(--default-color);
        height: 45px;
    }
    .headere_mobile .header_right_blk {
        width: calc(100% - 50px);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .headere_mobile .mob_site_content {
        width: 100%;
        overflow: auto;
        height: 100%;
    }
    .menu_close {
        padding: 20px;
        pointer-events: none;
    }
    .headere_mobile .mob_site_content li a {
        justify-content: space-between;
        color: var(--default-color);
    }
    .headere_mobile .header_sub_menu .dropdown-menu {
        position: static !important;
        box-shadow: none;
        display: block;
    }
    .headere_mobile .shop_menurow>* {
        width: 50% !important;
    }
    .headere_mobile .shop_menurow>* .shop_menu_li {
        border: none;
    }
    .headere_mobile .shop_menurow {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
    }
    .headere_mobile .shop_menurow>* {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
    }
    .headere_mobile .main_menu_blk ul li:not(:last-child) {
        margin-right: 0;
    }
}

@media only screen and (max-width: 1024px) and (min-width:768px) {
    body .product_ti_des, body .product_ti_des + * {
        width: 100%;
    }
    .flavour_accordation + .cart_quantity.quan_cart_blk {
        margin-bottom: 15px;
    }
    .mob_menu_wrapper.d-md-none {
        display: block !important;
    }
    .mob_menu_wrapper.d-md-none .mob_header_logo {
        display: none;
    }
    .mob_menu_wrapper.d-md-none .mob_site_content {
        display: flex;
        justify-content: center;
    }
    .headere_mobile .header_right_blk {
        display: none;
    }
    .menu_close {
        display: none !important;
    }
    .header_blk {
        display: flex;
        flex-direction: column-reverse;
    }
    .mob_menu_wrapper.d-md-none {
        display: block !important;
        padding: 15px 0;
        border-top: 1px solid var(--default-color);
    }
    .mob_menu_wrapper.d-md-none .main_menu_blk {
        display: block;
    }
    .shop_menurow .w-20.col {
        width: 33.33% !important;
    }
    body .header_sub_menu .dropdown-menu {
        top: 172px !important;
    }
    .login_li{
        display: none !important;
    }
    .cart_row.row>* {
        width: 100%;
        flex: inherit;
        max-width: inherit;
        padding-bottom: 34px;
    }
}
/**** css changes on 19/03/2023 - Ends **********/

/**** css changes on 24/03/2023 **********/
@media only screen and (max-width: 767px) {
    .quan_cart_blk .flavour_accordation {
        width: 100% !important;
        margin-bottom: 20px;
        margin-right: 0 !important;
    }
    body .product_ti_des, body .product_ti_des + * {
        width: 100%;
    }
    body .flavour_accordation .acc_collapse {
        position: static;
    }
    .order_pro_btn >*>*.quan_cart_blk {
        width: 100% !important;
    }
    .mobile_logo_blk  img {
        width: 150px;
    }
    body .login_container .user_login {
        width: 100%;
    }
    body .login_container .add_new_card .checkbox_label {
        margin: 0 !important;
        width: 50% !important;
    }
    body .customer_dash_full_view .cus_dash_rgt {
        padding: 40px 15px;
    }
    .subscribtion_table tr td:not(:first-child) {
        white-space: nowrap;
    }
    .subscribtion_table tr td:first-child .left_pro_de {
        width: 200px !important;
    }
    body .pro_carousel.browsing_his_carousel {
        width: 100%;
    }
    .subscribtion_table tr td:first-child .left_pro_de {
        width: 200px !important;
    }
    .table_search_select {
        width: 100%;
        justify-content: flex-end;
        margin-top: 20px;
    }
    body .order_det_blk {
        padding: 20px;
    }
    body .w-22 {
        width: 100% !important;
    }
    .sub_btm_det li {
        margin: 0 0 10px 0 !important;
        width: 100%;
    }
    body .add_new_btn {
        width: 100%;
    }
    .subscription_end_date_edit_calender >*>* {
        width: 100%;
        display: flex !important;
        justify-content: center;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1100px) {
    body .sub_btm_det li:not(:last-child) {
        margin-right: 15px;
    }
    body .active_cancel_mob_sub .mr_36 {
        margin-right: 15px !important;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .cus_dash_rgt .sub_current_div>*:first-child>* {
        width: 50%;
        flex:inherit;
        max-width: inherit;
    }
    body .sub_btm_det {
        margin-top: 20px;
        width: 100%;
        justify-content: flex-end;
    }
    .sub_btm_det li {
        width: auto;
        margin: 0 0 20px 18px !important;
    }
    .subscription_end_date_edit_calender>*>* {
        width: 30% !important;
        margin: 0 !important;
        justify-content: center;
        display: flex !important;
    }
    .contact_container .account_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
}
/**** css changes on 24/03/2023 - Ends **********/

/**** css changes refer on 24/03/2023 **********/
@media only screen and (max-width: 767px) {
    .register_recaptcha.contact_cppatcha .form-control {
        width: calc(100% - 190px);
    }
    button#refresh {
        opacity: 50%;
        margin-top: 20px;
    }
    body .banner_right_img {
        height: auto;
        width: 200px !important;
        margin-top: 20px;
    }
    body .formulated_ul {
        position: static;
        width: 63%;
        margin-bottom: 20px;
    }
    .rev_rgt.w-60 {
        width: 100% !important;
    }
    body .checkout_left_blk {
        padding: 30px 15px;
    }
    .checkout_ul li {
        width: 100% !important;
        flex-wrap: nowrap;
        flex-direction: row;
        border-radius: 50px !important;
        background-color: var(--sec_color);
    }
    .checkout_ul li.active .font_16 {
        color: var(--default-color);
    }
    .back_navigation {
        padding-bottom: 20px;
    }
    body .checkout_ul li .pl_10 {
        padding: 0 0 0 10px !important;
    }
    .left_methid {
        width: calc(50%);
    }
    .credit_card_radio {
        width: 100%;
        margin-bottom: 10px !important;
    }
    body .checkout_ul  {
        background-color: transparent;
    }
    .contact_container .account_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
    body .contact_cppatcha .form-control {
        width: calc(100% - 170px);
        margin: 0 0  0 20px;
    }
}
/**** css changes refer on 24/03/2023 - Ends **********/

/**** css changes affiliate on 24/03/2023 **********/
@media only screen and (max-width: 767px) {
    body .order_container {
        padding: 30px 15px;
    }
    .order_container .product_search_input {
        width: 100%;
        margin-top: 20px;
    }
    .headere_mobile .header_right_blk.aff_header_right_blk>ul>li {
        width: 100%;
        border: none;
        padding-right: 0;
        justify-content: flex-end;
    }
    .headere_mobile {
        padding-top: 20px;
    }
    .headere_mobile {
        height: auto !important;
    }
    .order_pro_list .order_pro_det {
        width: 100%;
        padding: 16px 0 20px 0;
    }
    .subscri_blk.choose_fla_blk {
        width: 100% !important;
        margin-bottom: 20px;
    }
    .order_pro_det .subscri_blk {
        margin: 0 0 20px 0 !important;
    }
    .sub_rgt >* {
        flex-wrap: nowrap;
    }
    body .order_container .container {
        padding: 0;
    }
    body .cart_pro_list_left {
        padding-right: 0;
    }
    .subscri_blk.cart_subscri_blk {
        margin: 0 0 20px 0 !important;
    }
    .cart_pro_list .cart_total h4 {
        margin-top: 20px !important;
    }
    .checkout_left_blk  .checkbox_label {
        margin-bottom: 20px !important;
    }
    body .cus_dah_left, body .cus_dash_rgt, body .leader_board_blk .vertical_cus_tab_wrapper, body .leader_board_blk .vertical_cus_tab_content {
        width: 100%;
    }
    #affiliateURL {
        margin-bottom: 20px !important;
    }
    .table_search .search_form input {
        margin-top: 0 !important;
    }
    .table_search_select select.form-control {
        margin-bottom: 5px !important;
    }
    .order_acc_footer >* {
        margin-top: 10px;
    }
    .subscribtion_table th {
        white-space: nowrap;
    }
    body .leader_board_blk .vertical_cus_tab_content {
        padding: 25px 0 0 0;
    }
    div#reactgooglegraph-1>*, div#reactgooglegraph-1>* svg, div#reactgooglegraph-2>*, div#reactgooglegraph-2>* svg, div#reactgooglegraph-3>*, div#reactgooglegraph-3>* svg {
        width: 100% !important;
    }
    .filter_row>*:not(:first-child) {
        margin-bottom: 19px;
    }
    .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_filter label, .dataTables_wrapper .dataTables_filter input {
        width: 100% !important;
    }
    .cus_dash_rgt>*>*:first-child>a {
        margin-top: 10px;
        display: flex;
        width: fit-content;
        margin-bottom: 20px;
    }
    .account_details_form .add_new_btn {
        margin-top: 16px;
    }
    .signature-modal {
        width: calc(100% - 30px) !important;
        margin: 10% auto !important;
    }
    .cus_dash_rgt>form {
        padding: 0;
    }
    body .commission-payout-method {
        width: 100%;
        margin: 0;
    }
    .com_pay_span span.d-block >span {
        display: block;
        margin-top: 2px;
    }
    .team_label_div>* {
        margin-bottom: 10px !important;
    }
    .subscribtion_table th, .subscribtion_table td {
        white-space: nowrap;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .team_label_div>* {
        margin-bottom: 10px !important;
    }
    .site_menu.sub_header {
        display: none;
    }
    body .cus_dah_left {
        width: 100%;
        flex: inherit;
        max-width: inherit;
    }
    div#reactgooglegraph-1>*, div#reactgooglegraph-1>* svg, div#reactgooglegraph-2>*, div#reactgooglegraph-2>* svg, div#reactgooglegraph-3>*, div#reactgooglegraph-3>* svg {
        width: 100% !important;
    }
    .mob_menu_wrapper.d-md-none .mob_site_content {
        flex-wrap: wrap;
        margin-bottom: 0px;
    }
    .hide_small_screen .col-md-8 {
        flex: inherit;
        max-width: inherit;
        width: 40%;
    }
    .hide_small_screen .col-md-8 {
        flex: inherit;
        max-width: inherit;
        width: 40%;
    }
    .cus_dash_rgt .row>* {
        flex: inherit;
        max-width: inherit;
        width: 50%;
    }
    body .cus_dash_rgt {
        width: calc(100%);
    }
    body .customer_dash_full_view .cus_dash_rgt {
        padding: 20px;
    }
    .subscribtion_table th, .subscribtion_table td {
        white-space: nowrap;
    }
    .main_menu_blk ul li:not(:last-child) {
        display: flex;
        margin-right: 15px;
    }
    .headere_mobile .main_menu_blk {
        padding: 0 25px;
    }
    .mob_menu_wrapper.d-md-none .main_menu_blk li.dropdown span {
        padding-right: 10px;
    }
    .main_menu_blk ul li a {
        padding-bottom: 10px !important;
        position: relative;
        color: var(--default-color);
        display: flex;
        font-size: 16px;
        font-family: 'helvetica_reg', sans-serif;
        line-height: 25px;
        letter-spacing: 0.07em;
        font-weight: 400;
        padding-right: 0 !important;
    }
    .mob_text_order.topemailphonecolor {
        text-align: center;
    }
    .main_menu_blk ul li a.active::after, .main_menu_blk ul li.dropdown.show>a::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--default-color);
        position: absolute;
        bottom: 10px;
    }
    body .order_accordatio {
        padding: 15px;
    }
    body .order_det_blk {
        padding: 20px;
    }
    .form_input_blk .form-control input.last_name_input {
        width: 50% !important;
    }
    .order_acc_header .left_acc {
        width: 50% !important;
    }
    .filter_row>* {
        margin-bottom: 20px;
    }
    body .customer_dash_full_view .container-fluid>* {
        min-height: inherit;
    }
    body .order_container {
        padding: 45px 0px;
    }
    .order_pro_list .col-md-8 {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
    .order_pro_list .col-md-4 {
        flex: inherit;
        max-width: inherit;
        width: 100%;
        padding-top: 20px;
    }
    .d-flex {
        flex-wrap: wrap;
    }
    .billing_blk  .d-flex {
        flex-wrap: nowrap;
    }
    .form_input_blk .form-control input {
        width: 50%;
    }
}
/**** css changes affiliate on 24/03/2023 - Ends **********/

/**** css changes affiliate on 24/03/2023  **********/
@media only screen and (max-width: 767px) {
    .order_pro_btn >*>*.flavour_accordation {
        width: 100% !important;
    }
    .order_pro_det .subscri_blk .subscri_blk.choose_fla_blk {
        margin: 0 !important;
    }
    .order_pro_img, .order_pro_img img {
        margin: 0 auto;
    }
    .order_pro_btn >* {
        width: 100%;
        justify-content: flex-start !important;
    }
    .order_pro_btn >*>* {
        width: auto !important;
        padding-top: 0 !important;
    }
    body .login_container {
        min-height: inherit;
    }
    .order_pro_btn .quan_cart_blk .cart_add_product_btn {
        margin-left: 0 !important;
        width: 100% !important;
    }
    .order_pro_btn  .pl_16 {
        width: 100%;
        text-align: right;
        padding-top: 16px;
    }
    body .login_container .registration-form .add_new_card .checkbox_label {
        width: 100% !important;
        margin-bottom: 8px !important;
    }
    .mobil_hide  {
        display: none !important;
    }
    .ship_rgt {
        width: 100%;
        margin-top: 10px;
    }
    body .shipping_left ,  body .contact_label + * {
        width: 100%;
    }
    body .contact_label + * {
        padding-top: 10px;
        padding-left: 0;
    }
    body .contact_label {
        width: 100%;
    }
    .checkout_left_blk .shipping_left .checkbox_label {
        margin: 0 !important;
    }
    .table_search_select label {
        width: 100% !important;
        margin-bottom: 6px !important;
    }
    .table_search_select.justify-content-end {
        justify-content: flex-start !important;
    }
    body .table_search_select .form-control {
        margin: 0 10px 10px 0 !important;
        width: calc(50% - 10px) !important;
    }
    .order_acc_footer>*:last-child {
        width: 100%;
    }
    .sub_current_div .text-right {
        text-align: left !important;
        margin-top: 10px !important;
    }
    .order_pro_det .subscri_blk {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .pro_qty_cat_blk {
        margin-top: 20px !important;
    }
    .subscri_blk.choose_fla_blk {
        width: 220px;
        margin-bottom: 0 !important;
    }
    .subscri_blk {
        margin-bottom: 29px !important;
    }
    .mob_text_order.topemailphonecolor {
        padding-top: 20px;
        border-top: 1px solid var(--default-color);
    }
    .table_search.table_header_search {
        margin-top: 20px;
    }
    .MyAccount-content .col-md-6 {
        max-width: inherit;
        flex: inherit;
        width: 100%;
        padding-bottom: 20px;
    }
    body .form_input_blk .form-control input {
        width: 50%;
    }
}
/**** css changes affiliate on 24/03/2023 - Ends **********/

/*** css     ***/
@media only screen and (max-width: 767px) {
    body .header_blk {
        position: static;
    }
    body .warning_c_alert_wrapper.alert.alert-danger.warning_wrapper_show {
        min-width: 90%;
    }
    body .pro_head_blk, body .input_bundle_btn {
        width: 100%;
    }
    .input_bundle_btn .w-90 {
        width: 100% !important;
    }
    .mb_20>.subscri_blk.choose_fla_blk {
        margin: 0;
    }
    body .order_pro_btn >*>*.cart_quantity.quan_cart_blk {
        width: 100% !important;
        margin-bottom: 20px;
    }
    .subscri_blk {
        padding: 8px 14px !important;
    }
    .mobilerespons_order_one {
        order: 1;
    }
    .mobilerespons_order_two {
        order: 2;
    }
    .mobilerespons_dnone {
        display: none;
    }
    .mobilerespons_dfelx {
        display: flex !important;
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .custom_bundle_blk .col-md-8, .custom_bundle_blk .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 1300px) {
    .learn_menu_blk {
        max-width: 13%;
        flex: inherit;
    }
    .contact_footer_blk {
        flex: inherit;
        max-width: 27%;
    }
}
